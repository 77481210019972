.spaceinfo-top-img img{
    width: 100%;
    height: 200px!important;
    object-fit: cover;
}
.space-guide-card{
    background-color:var(--ui-background);
    border-radius:0.5rem;
}
.common-space-info-card-h{
    height: calc(100vh - 388px);
    overflow-y: auto;
}

.spaceinfo-container{
    box-sizing: border-box;
}