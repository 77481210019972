.oneLensScheduleView .e-header-cells.e-disable-dates
{
    display: none;
}

.oneLensScheduleView .e-resource-cells.e-disable-dates
{
    height: 63px;
}

.oneLensScheduleView .e-schedule-table
{
    tbody 
    {
        tr.e-header-row:nth-child(2)
        {
            display: none;
        }
    }
}

.oneLensScheduleView.e-schedule .e-schedule-table .e-work-cells.e-disable-cell
{
    background-color: rgba(0, 0, 0, 0.08);
}
