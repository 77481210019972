.e-resource-text
{
    div
    {
        padding: 0rem 0rem 0rem 0.5rem;
    }
}

#room-info
{
    border-style: none;
}

.e-schedule .e-timeline-view .e-resource-cells
{
    border-style: none; /* remove borders between rows in header resource */
    height: 60px;
    padding-left: 15px;
    padding-right: 0;
    vertical-align: middle;
}

.e-schedule .e-timeline-view .e-resource-left-td
{
    border-style: none; /* remove borders from header resource - left hand side part of scheduleComponent grid */
}

.timeline-resource.e-schedule .e-schedule-table
{// hide first row of Flex schedule View table which shows the [date, day of the week] otherwise.
    tbody 
    {
        tr.e-header-row:nth-child(1)
        {
            display: none;
        }
    }
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td {
    vertical-align: bottom;
}

.timeline-resource.e-schedule.e-device .e-timeline-view .e-resource-left-td {
    width: 75px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td {
    width: 450px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    display: flex;
    font-weight: 500;
    padding: 0 !important;
    height: 36px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #0000001f;
    border-style: solid;
    border-width: 0 0 0 0;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: 500;
    height: 36px;
    line-height: 36px;
    padding-left: 5px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(1) {
    flex-basis: 36%
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(2) {
    flex-basis: 37%
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(3) {
    flex-basis: 15%
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(4) {
    flex-basis: 5%
}

.timeline-resource.e-schedule:not(.e-rtl) .e-timeline-view .e-resource-left-td .e-resource-text > div:last-child {
    border-right: 0;
}

.e-schedule.e-rtl .e-timeline-view .e-resource-cells, 
.e-schedule.e-rtl .e-timeline-month-view .e-resource-cells {
    padding-right: 0;
}

.e-schedule.e-rtl .template-wrap {
    text-align: right;
}

.timeline-resource.e-schedule .e-timeline-view .e-block-appointment {
    align-items: center;
    display: flex;
    justify-content: center;
}

.bootstrap4 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 37px;
}

.bootstrap4 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 37px;
    line-height: 37px;
}

.fluent-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap > div,
.fluent-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #292827;
}

.material3 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.material3-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 40px;
}

.material3 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
.material3-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 40px;
    line-height: 40px;
}

.material3 .timeline-resource.e-schedule .e-resource-cells .template-wrap > div,
.material3 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #c4c7c5;
}

.material3-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap > div,
.material3-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #444746;
}

.material-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap > div,
.material-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #616161;
}

.fabric-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap > div,
.fabric-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #414040;
}

.bootstrap-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap > div,
.bootstrap-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #505050;
}

.bootstrap5-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap > div,
.bootstrap5-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #444c54;
}

.tailwind-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap > div {
    border-color: #4b5563;
}

.highcontrast .timeline-resource.e-schedule .e-resource-cells .template-wrap > div {
    border-color: #969696;
}

.bootstrap4.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5-dark.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 39px;
}

.bootstrap4.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 39px;
    line-height: 39px;
}

.tailwind .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.tailwind-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 39px;
}

.tailwind .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
.tailwind-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 39px;
    line-height: 39px;
}

.tailwind .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    background-color: #f3f4f6;
    border-color: #e5e7eb;
}

.tailwind-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    background-color: #374151;
    border-color: #4b5563;
}

.tailwind.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.tailwind-dark.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 40px;
}

.tailwind.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
.tailwind-dark.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 40px;
    line-height: 40px;
}

.highcontrast .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 36px;
}

.highcontrast .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #969696;
}

.timeline-resource.e-schedule .template-wrap {
    display: flex;
    height: 60px;
    text-align: left;
}

.timeline-resource.e-schedule .template-wrap > div {
    border: 1px solid #0000001f;
    border-radius: 2px;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: 500;
    // line-height: 58px;
    overflow: hidden;
    padding: 1rem 0rem 1rem 0.5rem;
    text-overflow: ellipsis;
    align-content: center;
}

.timeline-resource.e-schedule .template-wrap div:nth-child(1) {
    flex-basis: 35%
}

.timeline-resource.e-schedule .template-wrap div:nth-child(2) {
    flex-basis: 35%
}

.timeline-resource.e-schedule .template-wrap div:nth-child(3) {
    flex-basis: 12%
}

.timeline-resource.e-schedule .template-wrap div:nth-child(4) {
    flex-basis: 8%
}

.material3 .timeline-resource.e-schedule .template-wrap > div,
.material3-dark .timeline-resource.e-schedule .template-wrap > div {
    line-height: 83px;
}

.timeline-resource.e-schedule:not(.e-rtl) .template-wrap > div:last-child {
    border-right: 0;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-cells,
.timeline-resource.e-schedule .e-timeline-month-view .e-resource-cells {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.timeline-resource.e-schedule .e-timeline-view .e-date-header-wrap table col,
.timeline-resource.e-schedule .e-timeline-view .e-content-wrap table col {
    width: 100px;
}

.timeline-resource.e-schedule .e-read-only {
    opacity: 0.8;
}

@media (max-width: 550px) {
    .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td {
        width: 100px;
    }
    .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
    .timeline-resource.e-schedule .e-resource-cells .template-wrap > div {
        flex: 0 0 100%;
    }
    .timeline-resource.e-schedule .e-resource-cells .template-wrap > div:first-child {
        border-right: 0;
    }
    .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div:first-child {
        border-right: 0;
    }
    .timeline-resource.e-schedule .room-type,
    .timeline-resource.e-schedule .room-capacity {
        display: none;
    }
}

.timeline-resource.e-schedule .e-timeline-view .e-time-slots span
{
    position: relative;
    left: -21px;
}

.timeline-resource.e-schedule .e-timeline-view .e-work-cells.e-disable-cell
{
    background-color: rgba(0, 0, 0, 0.08);
}

/* view booking dialog */

.schedule-dialog-row
{
    /* display: 'flex', alignItems: 'start', gap: '0.5em', mb: '0.5em */
    display: flex;
    align-items: start;
    gap: 0.5em;
    margin-bottom: 0.5em;
}

p.schedule-dialog-info
{
    /*fontSize: '1.25em', fontFamily: 'Source Sans Pro', fontWeight: 'bold' */
    font-size: 1.25em; 
    font-family: Source Sans Pro; 
    font-weight: bold;
}

p
{
    /*fontSize: '1.25em', fontFamily: 'Source Sans Pro', fontWeight: 'bold' */
    font-size: 1.25em; 
    font-family: Source Sans Pro; 
    font-weight: bold;
}