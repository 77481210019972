
.ibss-dialog
{
}

.ibss-dialog--default .ibss-dialog__icon
{
    display: none;
}

.ibss-dialog--error .ibss-dialog__icon
{
    padding: 4px 0 0 8px;
    width: 46px;
    height: 40px;
}

.ibss-dialog__main
{
}

.ibss-dialog__header
{
    font-size: 25px;
    color: var(--ui-text);
    background-color: var(--ui-background);
    border-radius: 10px 10px 0px 0px;
    padding: 0px 58px;
    height: 89px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ibss-dialog--error .ibss-dialog__header
{
    font-size: inherit;
    background-color: inherit;
    height: inherit;
    padding: 0px 8px;
}

.ibss-dialog__content
{
    color: var(--ui-text);
    overflow-y: auto;
    background-color: var(--ui-background-alternate);
    padding: 20px 58px;
    box-sizing: border-box;
}

    .ibss-dialog__content ul
    {
        margin: 0;
        padding: 0 0 0 20px;
    }

.ibss-dialog--error .ibss-dialog__content
{
    padding: 20px 8px;
    font-size: 0.85rem;
}

.ibss-dialog__footer
{
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--ui-line-break);
    padding: 20px 0px;
}

.ibss-dialog--error .ibss-dialog__footer
{
    border-top: none;
    justify-content: left !important;
}
