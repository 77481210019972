.floorplan
{
    position: relative;
    height: calc(100% - 2px);
    min-height: 200px;
    min-width: 200px;
    border: solid var(--ui-text-alternate) 1px;
    border-radius: 7px;
    background-color: var(--ui-background-alternate);

    .floorplan-buttons
    {
        position: absolute;
        z-index: 1;
        top: 5px;
        left: 5px;

        .floorplan-pan
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 60px;
            border: solid var(--ui-text-alternate) 1px;
            border-radius: 30px;
            background-color: var(--ui-background);
        }

        .floorplan-north
        {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 20px;
            padding: 0 4px 4px 7px;
            /*background-color: blue;*/
        }

        .floorplan-north-east
        {
            position: absolute;
            cursor: pointer;
            top: 1px;
            left: 40px;
            width: 19px;
            height: 19px;
            border-radius: 0 19px 0 0;
            /*background-color: red;*/
        }

        .floorplan-east
        {
            position: absolute;
            cursor: pointer;
            top: 20px;
            left: 40px;
            padding: 3px 4px 1px 11px;
            /*background-color: blue;*/
        }

        .floorplan-south-east
        {
            position: absolute;
            cursor: pointer;
            top: 40px;
            left: 40px;
            width: 19px;
            height: 19px;
            border-radius: 0 0 19px 0;
            /*background-color: red;*/
        }

        .floorplan-south
        {
            position: absolute;
            cursor: pointer;
            top: 40px;
            left: 20px;
            padding: 5px 4px 0px 7px;
            /*background-color: blue;*/
        }

        .floorplan-south-west
        {
            position: absolute;
            cursor: pointer;
            top: 1px;
            left: 1px;
            width: 19px;
            height: 19px;
            border-radius: 19px 0 0 0;
            /*background-color: red;*/
        }

        .floorplan-west
        {
            position: absolute;
            cursor: pointer;
            top: 20px;
            left: 0;
            padding: 3px 9px 1px 6px;
            /*background-color: blue;*/
        }

        .floorplan-north-west
        {
            position: absolute;
            cursor: pointer;
            top: 40px;
            left: 1px;
            width: 19px;
            height: 19px;
            border-radius: 0 0 0 19px;
            /*background-color: red;*/
        }

        .floorplan-centre
        {
            position: absolute;
            cursor: pointer;
            top: 20px;
            left: 20px;
            padding: 3px 3px 1px 4px;
            /*background-color: red;*/
        }

        .floorplan-zoom-in
        {
            position: absolute;
            border: solid var(--ui-text-alternate) 1px;
            color: var(--ui-text-alternate);
            background-color: var(--ui-background);
            cursor: pointer;
            top: 7px;
            left: 68px;
            padding: 3px 5px 3px 5px;
            border-radius: 10px 10px 0 0;
        }

        .floorplan-zoom-out
        {
            position: absolute;
            border: solid var(--ui-text-alternate) 1px;
            color: var(--ui-text-alternate);
            background-color: var(--ui-background);
            cursor: pointer;
            top: 30px;
            left: 68px;
            padding: 3px 5px 3px 5px;
            border-radius: 0 0 10px 10px;
        }
    }

    .react-transform-wrapper
    {
        width: 100%;
        height: 100%;
        border-radius: 7px;

        .react-transform-component
        {
            width: 100%;
            height: 100%;

            .floorplan-container
            {
                width: 100%;
                height: 100%;

                svg
                {
                    width: 100%;
                    height: 100%;

                    *[data-isspace="true"]
                    {
                        cursor: pointer;
                    }
                }
            }
            .floorplan-container-with-dropdown
            {
                width: 100%;
                height: 94%;

                svg
                {
                    width: 100%;
                    height: 94%;

                    *[data-isspace="true"]
                    {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.floor-plan-height-100{
    height: 100%;}
.floorplan-modal
{
    .modal-dialog
    {
        max-width: 318px !important;
        top: 50%;
        margin-top: -152px;

        .modal-content
        {
            width: 318px !important;
            height: 305px;

            .modal-body
            {
                padding: 0 !important;

                .space-card
                {
                    padding: 0;

                    .card
                    {
                        cursor: initial;
                    }
                }

                .floorplan-modal-button
                {
                    margin-left: 19px;
                    width: 280px;
                }
            }
        }
    }
}

.floorplan-hasBookingData
{
    .modal-dialog
    {
        .modal-content
        {
            height: 340px;
        }
    }
}

.floorplan-noBookingData
{
    .modal-dialog
    {
        .modal-content
        {
            height: 400px;
        }
    }
}

.floor-space-card
{
    height: auto;

    .card
    {
        height: auto;
        .space-card__type
        {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-size: 0.625rem;
            padding-left: 19px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.69px;
            text-transform: uppercase;
            color: var(--ui-text-alternate);
        }

        .space-card__name
        {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-size: 1.125rem;
            margin-left: 19px;
            margin-top: 4px;
            line-height: 23px;
            display: flex;
            align-items: center;
            color: var(--ui-text);
            max-width: 282px;
        }
    }

    .space-card__primary-info-row
    {
        width: 290px;
        height: 119px;
        position: absolute;
        z-index: 9;
        background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        padding: 0 14px 0 14px;

        .space-card__primary-info
        {
            margin: 0 0 2px 0;
            padding-left: 0;
            padding-right: 0;

            .space-card__icon
            {
                img
                {
                    filter: invert(143%) sepia(158%) saturate(161%) hue-rotate(570deg) brightness(100%) contrast(1000%);
                    height: 18px;
                    width: 18px;
                    padding: 0 12px 0 5px;
                }
            }

            .space-card__text
            {
                display: flex;
                overflow: hidden;
                height: 36px;
                white-space: normal;
            }
        }
    }

    .card-img-top
    {
        width: 318px;
        object-fit: cover;
        height: 119px;
        z-index: 2;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
    }

    .border
    {
        border: 1px solid var(--ui-mid-tone) !important;
        border-radius: 9px;
    }

    .card-selected {
        border: 3px solid var(--ui-primary-light) !important;
        box-shadow: 0px 4px 4px 0px #00000040;
    }
    .focused {
       
        outline: none; 
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        background-color: #e0e0e0;
    }
}