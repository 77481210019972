.pageTitle {
    font-family: Source Sans Pro;
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--ui-text);
}

.columnHeading {
    font-family: Source Sans Pro;
    font-size: 1.5rem;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
}

.columnSubheading {
    font-family: Source Sans Pro;
    font-size: 0.875rem;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

.noResultsHeading {
    font-family: 'Source Sans Pro';
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.noResultsSubText {
    font-family: 'Source Sans Pro';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
}

.alternativeSpace {
    font-family: Source Sans Pro;
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 26px;
}

.bookingTimeCard {
    display: flex;
    border-radius: 8px;
    background-color: var(--ui-line-break);
    height: 100px;
    width: 49%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TimeCardText {
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.125em;
    line-height: 1.5;
}

.TimeCardHeader {
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5;
}

.setupResetTimeCard {
    text-align: center;
    align-self: center;
    justify-content: center;
}
.recurring-empty-cards{
    border: 1px dashed var(--ui-text-light);
     border-radius: 8px; 
     height: calc(100vh - 196px); 
     display:flex;
     justify-content:center;
     align-items:center;
}