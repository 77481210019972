.instruction-step-container {
    display: flex;
    gap: 10px;
    align-content: center;
    margin-bottom: 30px;
}

.instruction-step-number {
    border-radius: 50px;
    background: var(--ui-light-pastel-blue);
    color: var(--ui-secondary);
    min-width: 35px;
    height: 35px;
    text-align: center;
    align-content: center;
    font-weight: 500;
    font-family: 'Roboto';
}

.instruction-step-text {
    align-content: center;
    font-family: "Source Sans Pro";
    font-size: 14px;
}