.MuiDrawer-paper{
    width:40%;
  }
.swap-space-card{
    border:1px solid var(--ui-line-break);
    border-radius: 0.5rem;
    padding:0.5rem;
    margin-top:1rem;
    margin-bottom:1rem;
}
.swap-space-card-img{
    width: 100%;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
    img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
}