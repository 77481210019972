.order-summary-container
{
    width: 350px;
    padding: 16px;
    border-radius: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;
    cursor: pointer;
    border: 1px grey solid;
}

.selected
{
    border: 2px #2E7BB9 solid;
}

.summary-header
{
    align-self: stretch;
    justify-content: center;
    align-items: flex-start;
    gap: 16;
    display: inline-flex;
}

.order-name
{
    flex: 1 1 0;
    word-wrap: break-word;
}

.order-details-container
{
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    display: inline-flex;
}

.order-details-container
{
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: flex;
}

.use-unavailable-menu
{
    background-color: var(--ui-error-pastel);
}