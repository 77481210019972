.bookingsDashboard-pagination
{
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    border-color: var(--ui-mid-tone);
}

.bookingsDashboard-paragraph
{
    font-family: 'Source Sans Pro' !important;
    font-weight: 600 !important;
    font-style: normal;
    padding-top: 1rem;
}

.bookingsDashboard-subHeader
{
    margin-left: 12px;
}

.bookingsDashboard-appointment.e-inner-wrap
{
    min-height: 58px;
}

.bookingsDashboard-appointment .icons-row
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
}

.bookingsDashboard-appointment .show-more
{
    display: flex;
    justify-content: flex-end;
}

.setup-teardown-icons-container
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    padding-top: 5px;
}


.icons-row.setup-teardown
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bookingsDashboard-appointment
{
    @at-root .e-appointment-details{
        min-height: 58px;
    }
}

.onelens-bookings-dashboard .e-appointment
{
    min-height: 85px;
    overflow: hidden;
}

.bookingsDashboard-text
{
    font-family: 'Source Sans Pro' !important;
    font-style: normal;
    font-weight: 600 !important;
}

.onelens-bookings-dashboard.e-schedule .e-schedule-table
{// hide first row of Flex schedule View table which shows the [date, day of the week] otherwise.
    tbody 
    {
        tr.e-header-row:nth-child(1)
        {
            display: none;
        }
    }
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td {
    vertical-align: bottom;
}

.onelens-bookings-dashboard.e-schedule.e-device .e-timeline-view .e-resource-left-td {
    width: 75px;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td {
    width: 450px;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    display: flex;
    font-weight: 500;
    padding: 0 !important;
    height: 36px;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #0000001f;
    border-style: solid;
    border-width: 0 0 0 0;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: 500;
    height: 36px;
    line-height: 36px;
    padding-left: 5px;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(1) {
    flex-basis: 36%
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(2) {
    flex-basis: 37%
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(3) {
    flex-basis: 15%
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text div:nth-child(4) {
    flex-basis: 5%
}

.onelens-bookings-dashboard.e-schedule:not(.e-rtl) .e-timeline-view .e-resource-left-td .e-resource-text > div:last-child {
    border-right: 0;
}

.e-schedule.e-rtl .e-timeline-view .e-resource-cells, 
.e-schedule.e-rtl .e-timeline-month-view .e-resource-cells {
    padding-right: 0;
}

.e-schedule.e-rtl .template-wrap {
    text-align: right;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-block-appointment {
    align-items: center;
    display: flex;
    justify-content: center;
}

.bootstrap4 .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5 .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 37px;
}

.bootstrap4 .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 37px;
    line-height: 37px;
}

.fluent-dark .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div,
.fluent-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #292827;
}

.material3 .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.material3-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 40px;
}

.material3 .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
.material3-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 40px;
    line-height: 40px;
}

.material3 .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div,
.material3 .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #c4c7c5;
}

.material3-dark .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div,
.material3-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #444746;
}

.material-dark .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div,
.material-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #616161;
}

.fabric-dark .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div,
.fabric-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #414040;
}

.bootstrap-dark .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div,
.bootstrap-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #505050;
}

.bootstrap5-dark .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div,
.bootstrap5-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #444c54;
}

.tailwind-dark .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div {
    border-color: #4b5563;
}

.highcontrast .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div {
    border-color: #969696;
}

.bootstrap4.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5-dark.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 39px;
}

.bootstrap4.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 39px;
    line-height: 39px;
}

.tailwind .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.tailwind-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 39px;
}

.tailwind .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
.tailwind-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 39px;
    line-height: 39px;
}

.tailwind .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    background-color: #f3f4f6;
    border-color: #e5e7eb;
}

.tailwind-dark .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    background-color: #374151;
    border-color: #4b5563;
}

.tailwind.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.tailwind-dark.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 40px;
}

.tailwind.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
.tailwind-dark.e-bigger .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    height: 40px;
    line-height: 40px;
}

.highcontrast .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
    height: 36px;
}

.highcontrast .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
    border-color: #969696;
}

.onelens-bookings-dashboard.e-schedule .template-wrap {
    display: flex;
    height: 106px;
    text-align: left;
}

.onelens-bookings-dashboard.e-schedule .template-wrap > div {
    border: 1px solid #0000001f;
    border-radius: 2px;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: 500;
    // line-height: 58px;
    overflow: hidden;
    padding: 1rem 0rem 1rem 0.5rem;
    text-overflow: ellipsis;
    align-content: center;
}

.onelens-bookings-dashboard.e-schedule .template-wrap div:nth-child(1) {
    flex-basis: 35%
}

.onelens-bookings-dashboard.e-schedule .template-wrap div:nth-child(2) {
    flex-basis: 35%
}

.onelens-bookings-dashboard.e-schedule .template-wrap div:nth-child(3) {
    flex-basis: 12%
}

.onelens-bookings-dashboard.e-schedule .template-wrap div:nth-child(4) {
    flex-basis: 8%
}

.material3 .onelens-bookings-dashboard.e-schedule .template-wrap > div,
.material3-dark .onelens-bookings-dashboard.e-schedule .template-wrap > div {
    line-height: 83px;
}

.onelens-bookings-dashboard.e-schedule:not(.e-rtl) .template-wrap > div:last-child {
    border-right: 0;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-cells,
.onelens-bookings-dashboard.e-schedule .e-timeline-month-view .e-resource-cells {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-work-cells, .e-schedule .e-timeline-month-view .e-work-cells
{
    height: 106px;
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-date-header-wrap table col,
.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-content-wrap table col {
    width: 100px;
}

.onelens-bookings-dashboard.e-schedule .e-read-only {
    opacity: 0.8;
}

@media (max-width: 550px) {
    .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td {
        width: 100px;
    }
    .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
    .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div {
        flex: 0 0 100%;
    }
    .onelens-bookings-dashboard.e-schedule .e-resource-cells .template-wrap > div:first-child {
        border-right: 0;
    }
    .onelens-bookings-dashboard.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div:first-child {
        border-right: 0;
    }
    .onelens-bookings-dashboard.e-schedule .room-type,
    .onelens-bookings-dashboard.e-schedule .room-capacity {
        display: none;
    }
}

.onelens-bookings-dashboard.e-schedule .e-timeline-view .e-work-cells.e-disable-cell
{
    background-color: rgba(0, 0, 0, 0.08);
}
